@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600&display=swap');

.sideButton {
	display: block;
	border-radius: 8px;
	width: 200px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 6px;
	margin-bottom: 6px;
	margin-left: 25px;
	margin-right: 25px;
	cursor:pointer;
	transition-duration: 0.4s;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	text-decoration: none;
	background-color: #EAF4F4;
	border: solid 1px #172A3A;
	text-align: center;
	color: #172A3A;
	vertical-align: middle;
}

.sideButtonSelected {
	background-color: #172A3A;
	border: solid 1px #EAF4F4;
	color: #EAF4F4;
}

.sideButton:hover {
	color: #EAF4F4;
	background-color: #172A3A;
  	border: solid 1px #EAF4F4;
}