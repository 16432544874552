@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full{
  background-color: #F6FFF8;
  height: 100vh;
}

.login{
  position: absolute;
  background-color: #FFA69E;
  color: #172A3A;
  border-radius: 8px;
  border: solid 1px #172A3A;
  width: 200px;
  height: 50px;
  transition-duration: 0.4s;
  top: 25px;
  right: 25px;
}

.login:hover{
  color: #FFA69E;
  background-color: #172A3A;
  border: solid 1px #FFA69E;
}

.contactSubmit{
  background-color: #FFA69E;
  color: #172A3A;
  border-radius: 8px;
  border: solid 1px #172A3A;
  width: 200px;
  height: 50px;
  transition-duration: 0.4s;
}

.contactSubmit:hover{
  color: #FFA69E;
  background-color: #172A3A;
  border: solid 1px #FFA69E;
}

h1{ 
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
  font-size: 50px;
  margin: 0;
  padding: 20px;
  color: #A4C3B2;
}

h2{
  font-family: 'Source Code Pro', monospace;
  font-weight: 500;
  font-size: 40px;
  margin: 0;
  padding-bottom: 5px;
  color: #172A3A;
}

h3{
  font-weight: 400;
  color: #172A3A;
}

.externalLink{
  color: #A4C3B2;
}

.contentLink{
  color: #A4C3B2;
}

.title{
  background-color: #6B9080;
  text-align: center;
  border-bottom: solid 5px #FFA69E;
  position: fixed;
  z-index: 10;
  width: 100vw;
}
.bottom{
  background-color: #F6FFF8;
  display: flex;
  padding-top: 105px;
}

.sidebar{
  width: 250px;
  background-color: #A4C3B2;
  padding: 10px 0px;
  border-right: solid 5px #FFA69E;
  overflow-y: scroll;
  position: fixed;
  height: calc(100vh - 120px);
}

button {
  cursor:pointer;
  outline: none;
  font-family: 'Montserrat', sans-serif;
}

table, th, td {
  padding: 10px;
  border: 1px solid #FFA69E;
  border-collapse: collapse;
}

.content{
  padding: 35px;
  padding-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 20px;
  padding-left: 290px;
}

th{
  color: #FFA69E;
}

.irithAndIrika{
  max-width: 600px;
  border: solid 5px #6B9080;
}

.code{
  background-color: #FFA69E;
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

b{
  color: #FFA69E;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.note{
  color: #6B9080;
  font-weight: 600;
}

.pageName{
  color: #A4C3B2;
  font-weight: 600;
}

.comment{
  color: #6c757d;
  display: inline-block;
}

p{
  margin-left: 30px;
  display: inline-block;
}

.indent1{
  margin-left: 40px;
}

.indent2{
  margin-left: 50px;
}

.indent3{
 margin-left: 60px; 
}

iframe{
  height: 600px;
}

.hintButton{
  background-color: #FFA69E;
  color: #172A3A;
  border-radius: 8px;
  border: solid 1px #172A3A;
  width: 200px;
  height: 50px;
  cursor:pointer;
  transition-duration: 0.4s;
}

.hintButton:hover{
  color: #FFA69E;
  background-color: #172A3A;
  border: solid 1px #FFA69E;
}

.problem{
  border: 2px solid #FFA69E;
  border-radius: 10px;
  padding: 10px;
}

.solution{
  border: 2px solid #cce3de;
  border-radius: 10px;
  padding: 10px;
}

.email{
  color: #A4C3B2;
  font-weight: 600;
}

.progress{
  margin-bottom: 30px;
  font-weight: 400;
}

.homeTable, .homeTableCell, .homeTableHeader{
  border: 3px solid #F6FFF8;
}

.homeTableHeader{
  color: #FFA69E;
}

.todoRow{
  background-color: #6B9080;
}

.inProgressRow{
  background-color: #A4C3B2;
}

.completeRow{
  background-color: #cce3de;
}
.sideButton {
	display: block;
	border-radius: 8px;
	width: 200px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 6px;
	margin-bottom: 6px;
	margin-left: 25px;
	margin-right: 25px;
	cursor:pointer;
	transition-duration: 0.4s;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	text-decoration: none;
	background-color: #EAF4F4;
	border: solid 1px #172A3A;
	text-align: center;
	color: #172A3A;
	vertical-align: middle;
}

.sideButtonSelected {
	background-color: #172A3A;
	border: solid 1px #EAF4F4;
	color: #EAF4F4;
}

.sideButton:hover {
	color: #EAF4F4;
	background-color: #172A3A;
  	border: solid 1px #EAF4F4;
}
